import Pikaday from 'pikaday';

export default class Datepicker {
  constructor(el) {
    this.DOM = { el: el };
    this.config = {
      format: 'YYYY/MM/DD',
      defaultDate: new Date(),
      setDefaultDate: new Date(),
      minDate: new Date(),
      toString(date, format) {
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        const year = date.getFullYear();
        return `${year}/${month}/${day}`;
      },
      parse(dateString, format) {
        const parts = dateString.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1] - 1, 10);
        const year = parseInt(parts[1], 10);
        return new Date(year, month, day);
      },
      yearSuffix: '年',
      showMonthAfterYear: true,
      i18n: {
        previousMonth : 'Previous Month',
        nextMonth     : 'Next Month',
        months        : ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
        weekdays      : ['日曜日','月曜日','火曜日','水曜日','木曜日','金曜日','土曜日'],
        weekdaysShort : ['日','月','火','水','木','金','土']
      }
    };

    this.init();
  }

  init() {
    if (!this.DOM.el) return;
    new Pikaday({
      ...this.config,
      field: this.DOM.el
    });
  }
}