import throttle from 'lodash/throttle';

export default class Animations {
  constructor() {
    this.blockList = Array.from(document.querySelectorAll('[data-anim-type]'));
    this.threshold = 1.2;

    this.onScroll = this.onScroll.bind(this);

    this.onScroll();
    this.bindEvents();
  }

  bindEvents() {
    window.addEventListener('scroll', throttle(this.onScroll, 16));
  }

  onScroll() {
    this.blockList.forEach(el => this.animate(el));
  }

  animate(el) {
    const isInView = el.getBoundingClientRect().top <= window.innerHeight / this.threshold;
    const appearOnLoad = el.getAttribute('data-anim-timing') === 'onload';

    if (isInView || appearOnLoad) {
      const delay = parseInt(el.getAttribute('data-anim-delay')) || 1;
      setTimeout(() => {
        el.classList.add('is-anim-over');
        // this.blockList.splice(this.blockList.indexOf(el), 1);
        this.blockList = this.blockList.filter(item => item !== el);
      }, delay);
    }
  }
}