export default class RsvOverlay {
  constructor(el) {
    this.DOM = { el: el };
    this.DOM.triggers = document.querySelectorAll('.js-rsvbtn');

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.bindEvents();
  }

  bindEvents() {
    this.DOM.triggers.forEach(trigger => {
      trigger.addEventListener('click', this.show);
    });
    this.DOM.el.addEventListener('click', this.hide);
  }

  show(e) {
    e.preventDefault();
    this.DOM.el.style.opacity = 1;
    this.DOM.el.style.visibility = 'visible';
  }

  hide() {
    this.DOM.el.style.opacity = 0;
    this.DOM.el.style.visibility = 'hidden';
  }
}
