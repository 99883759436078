export default class ThanksOverlay {
  constructor(el) {
    this.DOM = { el: el };

    this.hide = this.hide.bind(this);
    this.bindEvents();
    this.onLoad();
  }

  onLoad() {
    if (window.location.href.indexOf('?thankyou') > -1) {
      this.DOM.el.style.display = "block";
      setTimeout(() => this.hide(), 5000);
    }
  }

  bindEvents() {
    this.DOM.el.addEventListener('click', this.hide);
  }

  hide() {
    this.DOM.el.style.opacity = 0;
    this.DOM.el.style.visibility = 'hidden';
  }
}