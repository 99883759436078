class Search {
  constructor({formName, newWindow} = {}) {
    this.formName = formName;
    this.forms = document.getElementsByName(formName);
    this.newWindow = newWindow;
    this.executeSearch = this.executeSearch.bind(this);
  }

  bindEvents() {
    if (!this.forms) return;
    for (let elm of this.forms) {
      elm.querySelector('button').addEventListener('click', this.executeSearch);
    }
  }

  executeSearch(e) {
    e.preventDefault();
    let currentForm = e.currentTarget.parentNode;
    this.checkInDate = currentForm.checkin.value;
    this.nights = currentForm.nights.value;
    this.guests = currentForm.guests.value;
    this.rooms = currentForm.rooms.value;
    this.openResult(this.createUri());
  }

  openResult(uri) {
    if (this.newWindow) {
      window.open(uri);
    } else {
      window.location.href = uri;
    }
  }

  appendOptions(name, num) {
    for (let elm of this.forms) {
      for (let i = 1; i <= num; i++) {
        let option = document.createElement('option');
        option.value = i;
        option.appendChild(document.createTextNode(i));
        elm.querySelector('select[name="' + name + '"]').appendChild(option);
      }
    }
  }

  zeroPadding(number, digits) {
    return (Array(digits).join('0') + number).slice(-digits);
  }

  set checkInDate(value) {
    this._checkInDate = value;
  }

  get checkInYear() {
    let dt = new Date(this._checkInDate);
    return dt.getFullYear();
  }

  get checkInMonth() {
    let dt = new Date(this._checkInDate);
    return dt.getMonth() + 1;
  }

  get checkInDay() {
    let dt = new Date(this._checkInDate);
    return dt.getDate();
  }

  get checkOutYear() {
    let dt = new Date(this._checkInDate);
    dt.setDate(dt.getDate() + Number(this._nights));
    return dt.getFullYear();
  }

  get checkOutMonth() {
    let dt = new Date(this._checkInDate);
    dt.setDate(dt.getDate() + Number(this._nights));
    return dt.getMonth() + 1;
  }

  get checkOutDay() {
    let dt = new Date(this._checkInDate);
    dt.setDate(dt.getDate() + Number(this._nights));
    return dt.getDate();
  }

  set nights(value) {
    this._nights = value;
  }

  get nights() {
    return this._nights;
  }

  set guests(value) {
    this._guests = value;
  }

  get guests() {
    return this._guests;
  }

  set rooms(value) {
    this._rooms = value;
  }

  get rooms() {
    return this._rooms;
  }
}
export default Search;