export default class Menu {
  constructor(el) {
    this.DOM = { el: el };
    this.DOM.trigger = document.querySelector('.header__toggle');
    this.DOM.anchors = this.DOM.el.querySelectorAll('.anchor');

    this.state = {
      isOpen: false
    }

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);

    setTimeout(() => this.DOM.el.style.display = 'block', 500);

    this.bindEvents();
  }

  bindEvents() {
    this.DOM.trigger.addEventListener('click', this.toggleMenu);
    this.DOM.anchors.forEach(el => el.addEventListener('click', this.closeMenu));
  }

  toggleMenu() {
    this.state.isOpen ? this.closeMenu() : this.openMenu();
  }

  openMenu() {
    this.state.isOpen = true;
    document.body.classList.add('is-menu-open');
    this.DOM.el.classList.add('is-active');
    this.DOM.trigger.classList.add('is-active');
  }

  closeMenu() {
    this.state.isOpen = false;
    document.body.classList.remove('is-menu-open');
    this.DOM.el.classList.remove('is-active');
    this.DOM.trigger.classList.remove('is-active');
  }
}