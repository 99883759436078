import Search from './Search';

class YoyakuPro extends Search {
  constructor({formName, newWindow, hotelId, maxNights} = {}) {
    super(formName, newWindow);
    this.formName = formName;
    this.forms = document.getElementsByName(formName);
    this.newWindow = newWindow;
    this.hotelId = hotelId;
    this.maxNights = maxNights;
    this.initialize();
  }

  initialize() {
    this.appendOptions('nights', this.maxNights);
    this.appendOptions('guests', 10);
    this.appendOptions('rooms', 5);
    this.bindEvents();
  }

  openResult(uri) {
    let form = document.createElement('form');
    form.method = 'POST';
    form.action = uri;

    if (this.newWindow) form.target = '_blank';

    let values = {
      obj_year:     this.checkInYear,
      obj_month:    this.zeroPadding(this.checkInMonth, 2),
      obj_day:      this.zeroPadding(this.checkInDay, 2),
      obj_stay_num: this.nights,
      obj_per_num:  this.guests,
      obj_room_num: this.rooms,
    };

    for (let key in values) {
      let request = document.createElement('input');
      request.type = 'hidden';
      request.name = key;
      request.value = values[key];
      form.appendChild(request);
      document.body.appendChild(form);
    }

    form.submit();
  }

  createUri() {
    let uri = 'https://www5.489pro.com/asp/489/menu.asp?id=' + this.hotelId + '&ty=ser';
    return uri;
  }
}
export default YoyakuPro;