import Swiper from '../vendors/swiper.min.js';

export default class Sliders {
  static heroSlider() {
    return new Swiper('.hero__container', {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    });
  }

  static conceptSlider() {
    return new Swiper('.concept__slider', {
      slidesPerView: 4,
      spaceBetween: 0,
      loop: true,
      speed: 750,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      breakpoints: {
        768: {
          slidesPerView: 2.5,
          centeredSlides: true
        },
        576: {
          slidesPerView: 1.5,
          centeredSlides: true
        }
      }
    });
  }

  static roomSliders() {
    const roomBlocks = document.querySelectorAll('.roomblock');
    let options = {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      }
    };

    const createSlider = el => {
      const slider = el.querySelector('.roomblock__slider');

      new Swiper(slider, {
        ...options,
        pagination: {
          el: el.querySelector('.roomblock__pagination')
        }
      });
    }

    roomBlocks.forEach(block => createSlider(block));
  }

  static amenitySlider() {
    return new Swiper('.amenity__slider', {
      slidesPerView: 4,
      spaceBetween: 0,
      loop: true,
      speed: 750,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      breakpoints: {
        768: {
          slidesPerView: 2.5,
          centeredSlides: true
        },
        576: {
          slidesPerView: 1.5,
          centeredSlides: true
        }
      }
    });
  }
}