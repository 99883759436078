export default class {
  static anchorsInit() {
    const anchors = document.querySelectorAll('.anchor');

    const init = () => bind();

    const bind = () => [...anchors].map(anchor => anchor.addEventListener('click', scrollTo));

    const scrollTo = (e) => {
      e.preventDefault();
      const target = document.querySelector(e.currentTarget.getAttribute('href'));
      if (target) {
        setTimeout(() => {
          window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
        }, 10);
        // target.scrollIntoView({behavior: 'smooth'});
      }
    };

    return init();
  }


  static imageResize() {
    const images = document.querySelectorAll('.js-image-switch');
    const sp = '_sp.';
    const pc = '_pc.';
    const resizeWidth = 768;
    let resized = true;

    const init = () => {
      switchImage();
      bind();
    };

    const bind = () => window.addEventListener('resize', switchImage);

    const switchImage = () => {
      if (!resized) return;
      resized = false;

      const windowWidth = window.innerWidth;

      [...images].map((image) => {
        windowWidth >= resizeWidth
          ? image.setAttribute('src', image.getAttribute('src').replace(sp, pc))
          : image.setAttribute('src', image.getAttribute('src').replace(pc, sp));
      });

      setTimeout(() => resized = true, 200);
    };

    return init();
  }


  static isElement(el) {
    return el instanceof Element;
  }


  static forEachPolyfill() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this);
        }
      };
    }
  }
}
