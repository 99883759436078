export default class AsyncImage {
  constructor(el) {
    this.el = el;
    this.sourceImage = new Image();

    this.init();
  }

  init() {
    if (!this.el) return;

    this.isImage = !!this.el.getAttribute('data-src');
    this.sourceImage.src = this.el.getAttribute(this.isImage ? 'data-src' : 'data-background');

    this.sourceImage.onload = this.onImageLoad();
  }

  onImageLoad() {
    if (this.isImage) {
      this.el.src = this.sourceImage.src;
    }
    else {
      this.el.style.backgroundImage = `url(${this.sourceImage.src})`;
      this.el.style.opacity = 1;
    }
  }
}