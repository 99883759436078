import '../styles/main.scss';
import 'svgxuse';
import smoothscroll from 'smoothscroll-polyfill';
import Util from './modules/Utilities';
import Sliders from './modules/Sliders';
import Menu from './modules/Menu';
import Datepicker from './modules/Datepicker';
import RsvOverlay from './modules/RsvOverlay';
import ThanksOverlay from './modules/ThanksOverlay';
import YoyakuPro from './modules/YoyakuPro';
import Animations from './modules/Animations';
import AsyncImage from './modules/AsyncImage';

document.addEventListener('DOMContentLoaded', () => {
  document.body.classList.remove('loading');

  smoothscroll.polyfill();
  Util.forEachPolyfill();
  Util.anchorsInit();

  new RsvOverlay(document.querySelector('.reservation'));
  new ThanksOverlay(document.querySelector('.thankyou'));

  Sliders.heroSlider();
  Sliders.conceptSlider();
  Sliders.roomSliders();
  Sliders.amenitySlider();

  new Menu(document.querySelector('.menu'));
  new Datepicker(document.querySelector('.search__date'));
  new YoyakuPro({
    formName: 'search',
    newWindow: true,
    hotelId: 27000037,
    maxNights: 10
  });

  new Animations();
});

window.onload = () => {
  const asyncImages = document.querySelectorAll('[data-src], [data-background]');
  asyncImages.forEach(el => new AsyncImage(el));
}
